import { DateTime } from "luxon";

function fromISOToTimeLeft(date: string, unit: "days" | "hours" | "minutes" | "seconds") {
    const givenDate = DateTime.fromISO(date, { zone: "utc" });
    const currentDate = DateTime.now().toUTC();
    const diff = Math.round(currentDate.diff(givenDate, unit)[unit]);
    return diff;
}

// from "2024-04-27T19:36:28.000Z" to 3
export function fromISOToDaysLeft(date: string) {
    return fromISOToTimeLeft(date, "days");
}
export function fromISOToHoursLeft(date: string) {
    return fromISOToTimeLeft(date, "hours");
}
export function fromISOToMinutesLeft(date: string) {
    return fromISOToTimeLeft(date, "minutes");
}
export function fromISOToSecondsLeft(date: string) {
    return fromISOToTimeLeft(date, "seconds");
}

export function plusToISOTime(ISOTime: string, { d, h, m }: { d?: number, h?: number, m?: number }) {
    return DateTime.fromISO(ISOTime, { zone: "utc" }).plus({ days: d, hours: h, minutes: m }).toUTC().toISO();
}